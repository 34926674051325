import { ApiReturn } from '~/composables/use-api';

export type Role = ApiReturn<'getRoles'>[0];

export const useRoleStore = defineStore('role', {
  state: () => ({
    rolesById: {} as { [id: string]: Role }
  }),

  getters: {
    roles: (state) => Object.values(state.rolesById)
  },

  actions: {
    async fetchRoles() {
      const { data } = await useApi<Role[], any, any, Role[]>({
        url: '/v1/roles',
        method: 'GET'
      }, {
        transform: (response) => response.data
      });

      if (data.value) {
        data.value.forEach((role) => {
          this.rolesById[role.id] = role;
        });
      }
    }
  }
});
