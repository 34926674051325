import type { User } from '@podpro/api/src/core/auth0';
import { merge } from 'lodash-es';

import { ApiReturn } from '~/composables/use-api';

import { usePodcastStore } from './podcast';
import { useRoleStore } from './role';

type Podcast = ApiReturn<'getPodcast'>

export const useUserStore = defineStore('user', {
  state: () => ({
    usersById: {} as { [id: string]: User },
    currentUserId: null as string | null,
    userPodcastIds: {} as { [id: string]: string },

    // Global Constants

    hostingStatusLabels: {
      review: 'Review',
      approved: 'Approved',
      processing: 'Processing',
      active: 'Active',
      rejected: 'Rejected'
    },

    serviceLabels: {
      coaching: 'Coaching',
      hosting: 'Hosting'
    },

    roles: [
      'Podcaster',
      'Admin'
    ] as const
  }),

  getters: {
    isAdmin: () => useAuth().data.value?.roles?.includes('Admin'),

    user: (state) => (state.currentUserId ? state.usersById[state.currentUserId] : undefined),

    podcast(state): Podcast | undefined {
      const podcastStore = usePodcastStore();

      if (this.user) {
        const podcastId = state.userPodcastIds[this.user.user_id!];

        if (podcastId) {
          return podcastStore.byId[podcastId];
        }
      }

      return undefined;
    },

    users: (state) => Object.values(state.usersById),

    getUserPodcast: (state) => ((userId: string): Podcast | undefined => {
      const podcastStore = usePodcastStore();

      return podcastStore.byId[state.userPodcastIds[userId]];
    }),

    userPodcastInfo(state): { title: string, desc?: string } | undefined {
      if (this.user) {
        if (this.podcast) {
          return {
            title: this.podcast.title,
            desc: this.podcast.desc
          };
        }
        if (this.user.app_metadata?.podcast) {
          return {
            title: this.user.app_metadata.podcast.title,
            desc: this.user.app_metadata.podcast.desc
          };
        }
      }

      return undefined;
    }
  },

  actions: {
    async fetchUserPodcast(userId?: string) {
      const { data: authData } = useAuth();

      let id = userId;
      if (!id) {
        id = authData.value?.userId;
      }

      const { data } = await useApi<Podcast, any, any, Podcast>({
        url: encodeURI(`/v1/users/${id}/podcast`),
        method: 'GET'
      }, {
        transform: (response) => response.data
      });

      if (data.value) {
        const podcastStore = usePodcastStore();

        podcastStore.byId[data.value.id] = data.value;
        this.userPodcastIds[id!] = data.value.id;
      }
    },

    async fetchUser(userId?: string) {
      const { data: authData } = useAuth();

      let id = userId;
      if (!id) {
        id = authData.value?.userId;
      }

      const { data } = await useApi<User, any, any, User>({
        url: encodeURI(`/v1/users/${id}`),
        method: 'GET'
      }, {
        transform: (response) => response.data
      });

      if (data.value) {
        this.usersById[data.value.user_id!] = data.value;
        this.currentUserId = data.value.user_id!;
      }
    },

    async fetchUsers() {
      const { data } = await useApi<User[], any, any, User[]>({
        url: '/v1/users',
        method: 'GET'
      }, {
        transform: (response) => response.data
      });

      if (data.value) {
        data.value.forEach((user) => {
          this.usersById[user.user_id!] = user;
        });
      }
    },

    async updateUser(userId: string | undefined, data: Partial<User>) {
      const { data: authData } = useAuth();

      let id = userId;
      if (!id) {
        id = authData.value?.userId;
      }

      if (id) {
        this.usersById[id] = merge(this.usersById[id], data);

        await useApi({
          url: encodeURI(`/v1/users/${id}`),
          method: 'PATCH',
          data
        });
      }
    },

    async addUserRole(userId: string, roleId: string) {
      const roleStore = useRoleStore();

      const role = roleStore.rolesById[roleId];
      this.usersById[userId]?.roles?.push(role);

      await useApi({
        url: encodeURI(`/v1/users/${userId}/roles/${role.id}`),
        method: 'POST'
      });
    },

    async removeUserRole(userId: string, roleId: string) {
      const roleStore = useRoleStore();

      const role = roleStore.rolesById[roleId];
      if (this.usersById[userId]?.roles) {
        this.usersById[userId].roles = this.usersById[userId].roles?.filter((r) => r.id !== role.id);
      }

      await useApi({
        url: encodeURI(`/v1/users/${userId}/roles/${role.id}`),
        method: 'DELETE'
      });
    },

    async setUserPodcast(userId: string, podcastId: string) {
      await useApi({
        url: encodeURI(`/v1/users/${userId}/podcast/${podcastId}`),
        method: 'POST'
      });
    },

    async unsetUserPodcast(userId: string, podcastId: string) {
      await useApi({
        url: encodeURI(`/v1/users/${userId}/podcast/${podcastId}`),
        method: 'DELETE'
      });
    }
  }
});
