import { ApiReturn } from '~/composables/use-api';

type Podcast = ApiReturn<'getPodcast'>;

export const usePodcastStore = defineStore('podcast', {
  state: () => ({
    byId: {} as { [id: string]: Podcast }
  }),

  getters: {
    podcasts: (state) => Object.values(state.byId)
      .sort((a, b) => a.title?.localeCompare(b.title || '') || 0)
  },

  actions: {
    async fetchPodcasts() {
      const { data } = await useApi<Podcast[], any, any, Podcast[]>({
        url: '/v1/podcasts',
        method: 'GET'
      }, {
        transform: (response) => response.data
      });

      if (data.value) {
        data.value.forEach((podcast) => {
          this.byId[podcast.id] = podcast;
        });
      }
    }
  }
});
