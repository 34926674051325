import { operations as ApiOps } from '@podpro/api/types/api';
import axiosInstance, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { pick } from 'lodash-es';
import { AsyncDataOptions } from 'nuxt/dist/app/composables/asyncData';

type OkCode = '200' | '201' | '202';

export type ApiReturn<OpId extends keyof ApiOps> =
  ApiOps[OpId] extends { responses?: { [k in OkCode]?: { content?: { 'application/json'?: infer ResponseBody } } } }
    ? ResponseBody
    : never;

const MAX_ATTEMPTS = 30;

export default function useApi<T, D = any, E = any, DataT = AxiosResponse<T, D>>(
  config: AxiosRequestConfig<D> | (() => AxiosRequestConfig<D>),
  opts?: AsyncDataOptions<AxiosResponse<T, D>, DataT>
) {
  const runtimeConfig = useRuntimeConfig();
  const auth = useAuth();

  const axiosConfig = typeof config === 'function' ? config() : config;

  const key = JSON.stringify(pick(axiosConfig, ['url', 'method', 'params', 'data']));

  return useAsyncData<AxiosResponse<T, D>, AxiosError<E, D>, DataT>(key, async (ctx) => {
    const axiosConfig = typeof config === 'function' ? config() : config;

    const accessToken = auth.data.value?.accessToken;

    const axios = axiosInstance.create({
      baseURL: runtimeConfig.public.apiUrl,
      headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : undefined
    });

    let attempts = 0;
    axios.interceptors.response.use(async (response: AxiosResponse<T, D>) => {
      if (response.status === 202) {
        console.log('202 response');

        if (attempts < MAX_ATTEMPTS) {
          console.log('attempt', attempts);
          attempts++;

          return await new Promise((resolve, reject) => {
            setTimeout(() => {
              axios(axiosConfig)
                .then(resolve)
                .catch(reject);
            }, 2000);
          });
        }

        console.log('max attempts reached');
      }

      console.log('done');

      return response;
    });

    return await axios(axiosConfig);
  }, opts);
}
